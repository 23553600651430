{
    "name": "quirrel",
    "author": {
        "name": "Simon Knott",
        "email": "simon@quirrel.dev",
        "url": "https://quirrel.dev"
    },
    "repository": "quirrel-dev/quirrel",
    "homepage": "https://quirrel.dev",
    "license": "MIT",
    "files": [
        "dist",
        "*.d.ts",
        "*.cjs",
        "*.js",
        "*.mjs",
        "!jest.config.js",
        "README.md"
    ],
    "main": "dist/cjs/src/index.js",
    "module": "dist/esm/src/index.js",
    "version": "1.13.4",
    "engines": {
        "node": "14 || 15 || 16 || 17 || 18 || 19"
    },
    "bin": {
        "quirrel": "cli.js"
    },
    "scripts": {
        "start": "node dist/cjs/src/cli/index.js",
        "test": "DISABLE_TELEMETRY=true jest --forceExit --runInBand",
        "build": "run-s build:schemas build:tsc:** build:proxies build:**",
        "build:schemas": "json2ts -i src/api/scheduler/schemas/ -o src/api/scheduler/types/",
        "build:ui": "cd development-ui && npm ci && npm run build:fastify",
        "build:tsc:esm": "tsc --module es2015 --outDir dist/esm",
        "build:tsc:cjs": "tsc",
        "build:proxies": "./generate-proxies.sh",
        "prepack": "run-s build"
    },
    "devDependencies": {
        "@types/async-retry": "1.4.5",
        "@types/basic-auth": "1.1.3",
        "@types/body-parser": "1.19.2",
        "@types/connect": "3.4.35",
        "@types/cross-spawn": "6.0.2",
        "@types/expand-tilde": "2.0.0",
        "@types/jest": "29.2.2",
        "@types/js-yaml": "4.0.5",
        "@types/jsonwebtoken": "8.5.9",
        "@types/ms": "0.7.31",
        "@types/node-fetch": "2.6.2",
        "@types/parse-gitignore": "1.0.0",
        "@types/redis": "4.0.10",
        "@types/supertest": "2.0.12",
        "@types/uuid": "8.3.4",
        "@types/websocket": "1.0.5",
        "@types/ws": "8.5.3",
        "delay": "5.0.0",
        "jest": "29.2.2",
        "json-schema-to-typescript": "11.0.2",
        "npm-run-all": "4.1.5",
        "supertest": "6.3.1",
        "ts-jest": "29.0.3",
        "typescript": "4.9.5",
        "websocket": "1.0.34"
    },
    "dependencies": {
        "@babel/parser": "^7.14.7",
        "@babel/traverse": "^7.14.7",
        "@fastify/basic-auth": "^5.0.0",
        "@fastify/cors": "^8.1.0",
        "@fastify/rate-limit": "^7.5.0",
        "@fastify/static": "^6.5.0",
        "@fastify/swagger": "^8.0.0",
        "@fastify/swagger-ui": "^1.1.0",
        "@fastify/websocket": "^7.1.0",
        "@quirrel/owl": "^0.15.0",
        "@sentry/node": "^7.0.0",
        "@sentry/tracing": "^7.0.0",
        "@vercel/fetch-retry": "^5.0.3",
        "basic-auth": "2.0.1",
        "body-parser": "^1.20.0",
        "chalk": "4.1.2",
        "chokidar": "3.5.3",
        "commander": "^9.0.0",
        "conditional-type-checks": "1.0.6",
        "connect": "3.7.0",
        "cron-parser": "^4.6.0",
        "cross-fetch": "^3.1.4",
        "cross-spawn": "7.0.3",
        "dd-trace": "^3.5.0",
        "easy-table": "1.2.0",
        "expand-tilde": "2.0.2",
        "fast-glob": "^3.2.12",
        "fastify": "^4.9.2",
        "fastify-blipp": "3.1.0",
        "fastify-plugin": "^4.0.0",
        "ioredis": "^5.2.3",
        "ioredis-mock": "^8.2.2",
        "ipaddr.js": "^2.0.1",
        "js-yaml": "^4.1.0",
        "jsonwebtoken": "^9.0.0",
        "ms": "2.1.3",
        "node-fetch": "2.6.7",
        "open": "8.4.0",
        "opentracing": "^0.14.7",
        "parse-gitignore": "^1.0.1",
        "pino": "^8.6.1",
        "plausible-telemetry": "0.1.0",
        "posthog-node": "^2.1.0",
        "secure-e2ee": "0.4.0",
        "secure-webhooks": "^0.3.0",
        "superjson": "^1.10.1",
        "uuid": "^9.0.0",
        "zod": "^3.9.8"
    }
}
